<template>
  <div>
    <q-stepper
      v-model="step"
      ref="stepper"
      alternative-labels
      color="primary"
      animated
      @transition="stepTran"
    >
      <q-step
        :name="1"
        title="규제DB Excel 업로드"
        icon="settings"
        :done="step > 1"
      >
        <!-- excelupload -->
        <q-uploader
          flat bordered with-credentials
          ref="excel-upload"
          class="excel-upload"
          capture="environment"
          field-name="file" 
          method="POST"
          label="EXCEL UPLOAD"
          :auto-upload="false"
          :hide-upload-btn="true"
          :multiple="false"
          accept="xlsx"
          :max-file-size="100000000"
          :max-total-size="100000000"
          :max-files="1"
          :no-thumbnails="false"
          :disable="!editable"
          style="max-height:200px;min-height:200px;width: 100%;"
          @rejected="rejected"
          @added="added"
        >
          <!-- :filter="filter" -->
          <template v-slot:header="scope">
            <div class="row no-wrap items-center q-pa-none q-gutter-xs">
              <q-spinner v-if="scope.isUploading" class="q-uploader__spinner" />
              <div class="col" style="padding-top: 0px !important;margin-top: 0px !important;">
                <div class="q-uploader__title inline-block" style="padding: 4px 0px !important;font-size: 0.95em !important">EXCEL UPLOAD</div>&nbsp;&nbsp;
                <div class="q-uploader__subtitle inline-block" v-if="editable">{{ scope.uploadSizeLabel }} / {{ scope.uploadProgressLabel }}</div>
              </div>
              <q-btn v-if="scope.queuedFiles.length > 0&&editable"
                class="excel-upload-btn"
                icon="delete"
                dense outline 
                size="sm"
                :label="$language('파일 삭제')"
                @click="deleteFile(scope)">
              </q-btn>
            </div>
          </template>
          <template v-slot:list="scope">
            <template v-if="scope.files && scope.files.length > 0">
              <q-list separator>
                <q-item v-for="file in scope.files" :key="file.name">
                  <q-item-section>
                    <q-item-label class="full-width ellipsis">
                      {{ file.name }}
                    </q-item-label>

                    <q-item-label caption>
                      Status: {{ file.__status }}
                    </q-item-label>

                    <q-item-label caption>
                      {{ file.__sizeLabel }} / {{ file.__progressLabel }}
                    </q-item-label>
                  </q-item-section>

                  <q-item-section
                    v-if="file.__img"
                    thumbnail
                    class="gt-xs"
                  >
                    <img :src="file.__img.src">
                  </q-item-section>
                </q-item>
              </q-list>
            </template>
            <template v-else>
              <div class="empty_dscription">
                <div style="text-align: center; padding-bottom:5px" v-show="editable">
                  <q-chip outline square icon="info" style="width:100%">
                    Drag or Click
                  </q-chip>
                </div>
                <div class="q-pl-md txtfileinfo" style="text-align: left">
                  <span class="text-positive">
                    {{$language('업로드 가능 확장자')}}
                  </span>
                  : xlsx
                  <br/>
                  <span class="text-positive">
                    {{$language('업로드 가능 파일 수')}}
                  </span>
                  : 1
                  <br/>
                  <span class="text-positive">
                    {{$language('허용 파일 크기')}}
                  </span>
                  : 100MB
                </div>
                <q-uploader-add-trigger />
              </div>
            </template>
          </template>
        </q-uploader>
      </q-step>
      <q-step
        :name="2"
        title="양식 비교 및 업로드 준비"
        icon="create_new_folder"
        :done="step > 2"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            v-if="!fail.message1"
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-ball
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text regul-db-animation" 
                data-text="양식비교중입니다....">
                양식비교중입니다....
              </div>
            </template>
          </q-inner-loading>
          <q-inner-loading
            v-else
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-comment
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text" >
                {{fail.message1}}
              </div>
            </template>
          </q-inner-loading>

        </div>
      </q-step>

      <q-step
        :name="3"
        title="업로드 결과 저장"
        icon="add_comment"
        :done="step > 3"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            v-if="!fail.message2"
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-ball
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text regul-db-animation" 
                data-text="업로드 결과 저장 중....">
                업로드 결과 저장 중....
              </div>
              <div class="regul-db-loading-style-text">
                총 건 수 : {{regulDbUpload.totalCnt}}
              </div>
            </template>
          </q-inner-loading>
          <q-inner-loading
            v-else
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-comment
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text" 
                v-html="fail.message2">
              </div>
            </template>
          </q-inner-loading>
        </div>
      </q-step>

      <q-step
        :name="4"
        title="내역 저장"
        icon="add_comment"
        :done="step > 4"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            v-if="!fail.message3"
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-ball
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text regul-db-animation" 
                data-text="업로드 결과 저장 중....">
                업로드 결과 저장 중....
              </div>
              <div class="regul-db-loading-style-text">
                <ICountUp :startVal="0" :endVal="regulDbUpload.ingCnt" :decimals="0"
                  :duration="4" :options="countOptions" />
              </div>
            </template>
          </q-inner-loading>
          <q-inner-loading
            v-else
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-comment
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text" 
                v-html="fail.message3">
              </div>
              <div class="regul-db-loading-style-text">
                <ICountUp :startVal="0" :endVal="regulDbUpload.ingCnt" :decimals="0"
                  :duration="4" :options="countOptions" />
              </div>
            </template>
          </q-inner-loading>
        </div>
      </q-step>
      <q-step
        :name="5"
        title="유효성 체크"
        icon="create_new_folder"
        :done="step > 5"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            v-if="!fail.message4"
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-ball
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text regul-db-animation" 
                data-text="유효성 체크 중....">
                유효성 체크 중....
              </div>
            </template>
          </q-inner-loading>
          <c-table
            v-else-if="fail.message4 === 'VALID'"
            ref="table"
            title="유효성 실패 목록"
            :columns="validGrid.columns"
            :data="validGrid.data"
            :gridHeight="validGrid.height"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
          </c-table>
          <q-inner-loading
            v-else
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-comment
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text" 
                v-html="fail.message4">
              </div>
            </template>
          </q-inner-loading>
        </div>
      </q-step>
      <q-step
        :name="6"
        title="동일 파일 업로드 체크"
        icon="create_new_folder"
        :done="step > 6"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            v-if="!fail.message5"
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-ball
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text regul-db-animation" 
                data-text="최근 excel 업로드 건과 동일한 파일인지 검사 중....">
                최근 excel 업로드 건과 동일한 파일인지 검사 중....
              </div>
            </template>
          </q-inner-loading>
          <q-inner-loading
            v-else
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-comment
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text" 
                v-html="fail.message5">
              </div>
            </template>
          </q-inner-loading>
        </div>
      </q-step>
      <q-step
        :name="7"
        title="변경 내역 진행 중"
        icon="create_new_folder"
        :done="step > 7"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            v-if="!fail.message6"
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-ball
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text regul-db-animation" 
                data-text="변경된 내역 작성 중....">
                변경된 내역 작성 중....
              </div>
            </template>
          </q-inner-loading>
          <q-inner-loading
            v-else
            :showing="visible"
          >
            <template slot="default">
              <q-spinner-comment
                class="regul-db-spinner"
                size="5.5em"
              />
              <div 
                class="regul-db-loading-style-text" 
                v-html="fail.message6">
              </div>
            </template>
          </q-inner-loading>
        </div>
      </q-step>
      <q-step
        :name="8"
        title="완료"
        icon="create_new_folder"
        :done="step > 8"
      >
        <div class="regul-db-loading-style">
          <q-inner-loading
            :showing="visible"
          >
            <template slot="default">
              <q-icon 
                name="check"
                class="regul-db-complete-icon text-green"
              />
              <div 
                class="regul-db-loading-style-text" >
                완료
              </div>
              <q-btn 
                flat 
                class="regul-db-loading-style-text-sub"
                label="변경된 이력보기"
                @click="showHistory" />
            </template>
          </q-inner-loading>
        </div>
      </q-step>
    </q-stepper>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import ICountUp from "vue-countup-v2";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'regulDbUploadDetail',

  components: {
    ICountUp,
  },
  data() {
    return {
      regulDbUpload: {
        chmRegulDbUploadId: '',  // 규제DB업로드 결과 일련번호
        updateDt: '',  // 업로드 일시
        chmUploadStateCd: null,  // 업로드 상태
        addCnt: 0,  // 추가 건 수
        updateCnt: 0,  // 수정 건 수
        deleteCnt: 0,  // 삭제 건 수
        noChangeCnt: 0,  // 변함없음 건 수
        totalCnt: 0,  // 총 건 수
        failContents: '',  // 실패사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        ingCnt: 0,
        details: [],
        chemBases: [],
        chems: [],
      },
      step: 1,
      visible: true,
      isError: false,
      fail: {
        message1: '', // 엑셀 읽는 도중 발생한 에러
        message2: '', // 업로드 결과 저장 중 발생한 에러
        message3: '', // 내역 저장 중 발생한 에러
        message4: '', // 유효성 체크 도중 발생한 에러
        message5: '', // 동일 파일 업로드 체크 도중 발생한 에러
        message6: '', // 변경 내역 저장 중 발생한 에러
      },
      templateColName: [],
      templateHeader: [],
      sheetNames: [],
      countOptions: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "건 / 300건"
      },
      validGrid: {
        data: [],
        columns: [
          {
            name: 'failContents',
            field: 'failContents',
            label: '업로드 일시',
            align: 'left',
            style: 'width:300px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '화학물질명(CAS No.)',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'regulationBillCd',
            field: 'regulationBillCd',
            label: '규제법규코드',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'regulatedItemCd',
            field: 'regulatedItemCd',
            label: '규제항목코드',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'appliStandSign',
            field: 'appliStandSign',
            label: '적용기준<br/>부등호',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'flag',
            field: 'flag',
            label: '추가/수정<br/>/삭제',
            align: 'center',
            style: 'width:60px',
            sortable: true,
          },
          {
            name: 'notiDt',
            field: 'notiDt',
            label: '고시일',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },

        ],
        height: '600px'
      },
      editable: true,
      listUrl: '',
      uploadUrl: '',
      uploadDetailUrl: '',
      validUrl: '',
      validFailUrl: '',
      sameUrl: '',
      chemHistoryUrl: '',
      chemRegulHistoryUrl: '',
      popupOptions: {
        isFull: false,
        width: '70%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    truncateSize() {
      return 30;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.templateExcelFileUrl = selectConfig.sys.excelUpload.template.url;
      this.uploadUrl = transactionConfig.chm.db.regulDbUpload.upload.url
      this.uploadDetailUrl = transactionConfig.chm.db.regulDbUploadDetail.upload.url
      this.validUrl = transactionConfig.chm.db.regulDbUpload.valid.url
      this.validFailUrl = selectConfig.chm.db.regulDbUpload.validFail.url
      this.sameUrl = transactionConfig.chm.db.regulDbUpload.same.url
      this.chemHistoryUrl = transactionConfig.chm.db.regulDbUpload.chemHistory.url
      this.chemRegulHistoryUrl = transactionConfig.chm.db.regulDbUpload.chemRegulHistory.url
      // code setting
      // list setting
      this.getExcelTemplateHeader();
    },
    // getList() {
    //   this.$http.url = this.listUrl;
    //   this.$http.type = 'GET';
    //   this.$http.request((_result) => {
    //     this.grid.data = _result.data;
    //   },);
    // },
    stepTran(val) {
      let promises = [];
      let quotient = 0
      let remainder = 0
      switch(val) {
        case 2: // 양식 비교 및 업로드 준비
          /**
           * 업로드 컴포넌트가 가지는 event method를 통해 서비스 실행
           * 
           * 따라서 해당 경우에는 아무것도 하지 않음
           */
          break;
        case 3: // 업로드 결과 저장
          this.$set(this.regulDbUpload, 'ingCnt', 0)
          this.$set(this.regulDbUpload, 'updateDt', this.$comm.getTodayDateTime())
          this.$set(this.regulDbUpload, 'regUserId', this.$store.getters.user.userId)
          this.$set(this.regulDbUpload, 'totalCnt', this.regulDbUpload.details.length)
          this.$set(this.countOptions, 'suffix', `건 / ${this.regulDbUpload.details.length}건`)
          this.$http.url = this.uploadUrl;
          this.$http.type = 'POST';
          this.$http.param = this.regulDbUpload;
          this.$http.request((_result) => {
            if (_result.data !== 'FAIL') {
              this.$set(this.regulDbUpload, 'chmRegulDbUploadId', _result.data)
              this.$_.forEach(this.regulDbUpload.details, detail => {
                this.$set(detail, 'chmRegulDbUploadId', _result.data)
              })
              setTimeout(() => {
                // 업로드 결과 저장 완료
                this.$refs.stepper.next()
              }, 2000);
            } else {
              // 업로드 결과 저장 중 오류 발생으로 인한 FAIL MESSAGE 표시
              this.fail.message2 = '업로드 결과 저장 중 에러가 발생하였습니다.<br/>담당자에게 문의 바랍니다.'
            }
          },);
          break;
        case 4: // 내역 저장
          quotient = this.regulDbUpload.totalCnt / this.truncateSize // 몫
          remainder = this.regulDbUpload.totalCnt % this.truncateSize // 나머지
          if (remainder > 0) {
            quotient + 1;
          }
          for (let i = 0; i < quotient; i++) {
            promises.push({
              func: this.detailUpload(this.$_.slice(this.regulDbUpload.details, (i * this.truncateSize), this.truncateSize * (i + 1)))
            })
          }
          promises.push({
            func: this.completeDetailUpload()
          })
          this.$comm.orderedPromise(promises);
          break;
        case 5: // 유효성 체크
          this.$http.url = this.validUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.regulDbUpload; // 업로드 결과 id만 있으면 됨
          this.$http.request((_result) => {
            if (_result.data === 1) {
              setTimeout(() => {
                // 유효성 체크 완료
                this.$refs.stepper.next()
              }, 2000);
            } else {
              // 실패한 내역이 존재
              if (_result.data === -1) {
                // 테이블로 유효성 실패 내역 보여주기
                this.fail.message4 = 'VALID'
                this.$http.url = this.validFailUrl;
                this.$http.type = 'GET';
                this.$http.param = {
                  chmRegulDbUploadId: this.regulDbUpload.chmRegulDbUploadId
                }
                this.$http.request((_result) => {
                  this.validGrid.data = _result.data
                },);
              } else if (_result.data === -2) {
                this.fail.message4 = '유효성 체크 중 에러가 발생하였습니다.<br/>저장 중이던 내역 정보는 삭제됩니다.'
              }
            }
          },);
          break;
        case 6: // 동일 파일 업로드 체크
          this.$http.url = this.sameUrl;
          this.$http.type = 'PUT';
          this.$http.param = this.regulDbUpload; // 업로드 결과 id만 있으면 됨
          this.$http.request((_result) => {
            if (_result.data === 1) {
              setTimeout(() => {
                // 동일 파일 업로드 체크 완료
                this.$refs.stepper.next()
              }, 2000);
            } else {
              if (_result.data === -1) {
                // 전부 동일한 row로 동일한 파일이 올라갔다고 판단
                this.fail.message5 = '전부 동일한 데이터로 판단되었습니다.<br/>새로운 파일을 제공해 주십시오.'
              } else if (_result.data === -2) {
                this.fail.message5 = '동일파일 업로드 체크 중 에러가 발생하였습니다.<br/>저장 중이던 내역 정보는 삭제됩니다.'
              }
            }
          },);
          break;
        case 7: // 변경 내역 진행 중
          this.$comm.orderedPromise([
            {
              func: this.chemHistory
            },
            {
              func: this.chemRegulHistory
            },
          ]);
          break;
        default:
          // nothing...
          break;
      }
    },
    detailUpload(saveRows) {
      return new Promise((resolve, _reject) => {
        this.$http.url = this.uploadDetailUrl;
        this.$http.type = 'PUT';
        this.$http.param = {
          chmRegulDbUploadId: this.regulDbUpload.chmRegulDbUploadId,
          details: saveRows
        }
        this.$http.request((_result) => {
          if (_result.data === 1) {
            this.regulDbUpload.ingCnt += (saveRows && saveRows.length > 0 ? saveRows.length : 0); // this.truncateSize
            resolve(true);
          } else {
            if (_result.data === -1) {
              this.fail.message3 = '내역 저장 중 에러가 발생하였습니다.<br/>저장 중이던 내역 정보는 삭제됩니다.'
            } else if (_result.data === -2) {
              // 앞 step이 처리되었다면 해당 return 값이 올 수 없음
            }
            _reject("");
          }
        }, () => {
          _reject("");
        });
      });
    },
    completeDetailUpload() {
      return new Promise(resolve => {
        setTimeout(() => {
          // 내역 저장 완료
          this.$refs.stepper.next()
        }, 2000);
        resolve(true);
      });
    },
    chemHistory() {
      return new Promise((resolve, _reject) => {
        this.$http.url = this.chemHistoryUrl;
        this.$http.type = 'PUT';
        this.$http.param = this.regulDbUpload
        this.$http.request((_result) => {
          if (_result.data === 1) {
            resolve(true);
          } else {
            this.fail.message6 = '화학물질 변경내역 정리 중 에러가 발생하였습니다.<br/>저장 중이던 내역 정보는 삭제됩니다.'
            _reject("");
          }
        }, () => {
          _reject("");
        });
      });
    },
    chemRegulHistory() {
      return new Promise((resolve, _reject) => {
        this.$http.url = this.chemRegulHistoryUrl;
        this.$http.type = 'PUT';
        this.$http.param = this.regulDbUpload
        this.$http.request((_result) => {
          // 다 종료 되었으며 이력팝업 띄울거냐는 메세지를 화면에 표시
          if (_result.data === 1) {
            this.$refs.stepper.next()
            resolve(true);
          } else {
            this.fail.message6 = '화학물질별 규제항목 변경내역 정리 중 에러가 발생하였습니다.<br/>저장 중이던 내역 정보는 삭제됩니다.'
            _reject("");
          }
        }, () => {
          _reject("");
        });
      });
    },
    getExcelTemplateHeader() {
      let thisVue = this;
      this.$http.url = this.templateExcelFileUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        taskClassCd: 'REGUL_DB_UPLOAD',
      };
      this.$http.request(
        _result => {
          let cfb = XLSX.read(_result.data, {type: 'base64'});
          this.sheetNames = [];
          this.templateHeader = [];
          let sheetNum = 0
          this.sheetNames.push(cfb.SheetNames[sheetNum])
          this.templateHeader.push(thisVue.$comm.getHeaders(cfb.Sheets[cfb.SheetNames[sheetNum]]))
          this.templateColName = XLSX.utils.sheet_to_json(cfb.Sheets[cfb.SheetNames[sheetNum]], { raw: false, cellDates: true })[0]
        },
      );
    },
    getFileSizeTextByRound(_fileByteSize) {
      return this.$comm.bytesToSize(_fileByteSize);
    },
    rejected(info) {
      if (!info || info.length === 0) {
        return;
      }
      // 다시 파일을 올림으로 인해 메시지를 초기화 처리함
      this.isError = false;
      this.fail.message1 = '';
      this.$_.forEach(info, reject => {
        // accept, max-file-size, max-total-size, filter, etc
        switch(reject.failedPropValidation) {
          case 'max-file-size': // 파일용량 초과
          case 'max-total-size': // 파일 전체 용량 초과
            this.isError = true;
            // '첨부하신 "' + reject.file.name + '"의 용량이 지정된 용량보다 큽니다. (지정된 용량 : 10MB, 파일 용량 : ' + this.getFileSizeTextByRound(reject.file.size) + ')';
            this.fail.message1 += this.$language('첨부하신 파일의 용량이 지정된 용량보다 큽니다. 파일 용량 : ') + '(' +  this.getFileSizeTextByRound(reject.file.size) + ')';
            break;
          case 'max-files': // 업로드 갯수 초과
            this.isError = true;
            // '첨부하신 "' + reject.file.name + '"이 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다. (지정된 업로드 갯수 : 1)';
            this.fail.message1 = this.$language('첨부하신 파일의 지정된 업로드 갯수를 초과하여 업로드 되지 않았습니다.') + '(' +  this.uploaderSetting.limitCnt + ')';
            break;
          case 'accept': // 확장자 맞지않음
            this.isError = true;
            // '첨부하신 "' + reject.file.name + '"의 확장자가 올바르지 않습니다. (업로드 가능한 확장자 : xlsx)';
            this.fail.message1 = this.$language('첨부하신 파일의 확장자가 올바르지 않습니다.')+ '(' +  this.uploaderSetting.acceptExt + ')';
            break;
          case 'filter': // filter 걸린경우
            break;
          default:
            break;
        }
      })
    },
    added(files) {
      this.$refs['excel-upload'].reset()
      // 파일 올리기 완료
      this.$refs.stepper.next()
      /**
       * 파일을 다시 올리는 경우 업로드 현황 & Excel 결과 목록 초기화
       */
      this.isError = false;
      this.fail.message1 = '';
      this.regulDbUpload.details = [];
      /**
       * 엑셀의 헤더가 업로드 된 엑셀과 맞지 않을 경우를 체크
       */
      let thisVue = this;
      let reader = new FileReader(); 
      reader.readAsDataURL(files[0]);
      reader.onload = function () { 
        let result = reader.result;
        
        let base64Data = result.replace(('data:' + files[0].type + ';base64,'), '')
        let cfb = XLSX.read(base64Data, { 
          type: 'base64' 
          ,cellDates: true
        });

        let sheetNum = 1
        for (let i = 0; i < sheetNum; i++) {
          let uploadExcel = {
            header: thisVue.$comm.getHeaders(cfb.Sheets[cfb.SheetNames[i]]),
            data: XLSX.utils.sheet_to_json(cfb.Sheets[cfb.SheetNames[i]], { raw: false, cellDates: true }),
          }

          // 1. 헤더가 맞지 않은 경우 체크
          let headerCheck = thisVue.templateHeader[i].every((item, idx) => {
            return uploadExcel.header[idx] === item
          })
          if (!headerCheck) {
            thisVue.isError = true;
            thisVue.fail.message1 += thisVue.$language('업로드한 엑셀의 헤더가 올바르지 않습니다. 양식 다운로드하여 확인바랍니다.')
            break;
          }

          // 2. 데이터가 없는 경우 체크
          if (!uploadExcel.data || uploadExcel.data.length === 0) {
            thisVue.isError = true;
            thisVue.fail.message1 += thisVue.$language('업로드한 엑셀에 데이터가 없습니다. 확인 후 업로드 해주십시오.')
            break;
          }
          thisVue.$set(thisVue.regulDbUpload, 'details', []);
          thisVue.$_.forEach(uploadExcel.data, data => {
            let pushData = {};
            for(let colKey in thisVue.templateColName){
              thisVue.$set(pushData, thisVue.templateColName[colKey], data[colKey])
            }
            thisVue.regulDbUpload.details.push(pushData)
          });
          
        }

        if (!thisVue.isError) {
          // this.step = 2;
          thisVue.$refs.stepper.next()
        } else {
          // OUT
        }
      } 
    },
    deleteFile() {
      this.isError = false;
      Object.assign(this.$data.fail, this.$options.data().fail);
      this.$refs.stepper.goTo(1)
      this.regulDbUpload.details = [];
      this.$refs['excel-upload'].reset();
      this.$refs['excel-upload'].removeQueuedFiles();
    },
    showHistory() {
      this.popupOptions.title = '변경 이력'; // 변경 이력
      this.popupOptions.target = () => import(`${'./regulDbUploadHistory.vue'}`);
      this.popupOptions.param = {
        chmRegulDbUploadId: this.regulDbUpload.chmRegulDbUploadId
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHistoryPopup;
    },
    closeHistoryPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
<style>
.regul-db-spinner {
  color: #3498db;
}
.regul-db-complete-icon {
  font-size: 5.5em;
}

.regul-db-loading-style {
  background: white; /* #3498db; */
  height: 600px;
}
.regul-db-loading-style-text {
  font-size: 2.1em;
  font-weight: 600;
}
.regul-db-loading-style-text-sub {
  font-size: 1.1em;
  font-weight: 400;
  cursor: pointer;
}

.regul-db-animation {
  color: rgba(0, 0, 0, .3);
}
.regul-db-animation:before {
  content: attr(data-text);
  position: absolute;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  color: #3498db;
  animation: loading 3s linear;
  animation-iteration-count: infinite;
}

@keyframes loading {
    0% {
        max-width: 0;
    }
}
</style>