var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-stepper",
        {
          ref: "stepper",
          attrs: { "alternative-labels": "", color: "primary", animated: "" },
          on: { transition: _vm.stepTran },
          model: {
            value: _vm.step,
            callback: function ($$v) {
              _vm.step = $$v
            },
            expression: "step",
          },
        },
        [
          _c(
            "q-step",
            {
              attrs: {
                name: 1,
                title: "규제DB Excel 업로드",
                icon: "settings",
                done: _vm.step > 1,
              },
            },
            [
              _c("q-uploader", {
                ref: "excel-upload",
                staticClass: "excel-upload",
                staticStyle: {
                  "max-height": "200px",
                  "min-height": "200px",
                  width: "100%",
                },
                attrs: {
                  flat: "",
                  bordered: "",
                  "with-credentials": "",
                  capture: "environment",
                  "field-name": "file",
                  method: "POST",
                  label: "EXCEL UPLOAD",
                  "auto-upload": false,
                  "hide-upload-btn": true,
                  multiple: false,
                  accept: "xlsx",
                  "max-file-size": 100000000,
                  "max-total-size": 100000000,
                  "max-files": 1,
                  "no-thumbnails": false,
                  disable: !_vm.editable,
                },
                on: { rejected: _vm.rejected, added: _vm.added },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row no-wrap items-center q-pa-none q-gutter-xs",
                          },
                          [
                            scope.isUploading
                              ? _c("q-spinner", {
                                  staticClass: "q-uploader__spinner",
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "col",
                                staticStyle: {
                                  "padding-top": "0px !important",
                                  "margin-top": "0px !important",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "q-uploader__title inline-block",
                                    staticStyle: {
                                      padding: "4px 0px !important",
                                      "font-size": "0.95em !important",
                                    },
                                  },
                                  [_vm._v("EXCEL UPLOAD")]
                                ),
                                _vm._v("   "),
                                _vm.editable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "q-uploader__subtitle inline-block",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(scope.uploadSizeLabel) +
                                            " / " +
                                            _vm._s(scope.uploadProgressLabel)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            scope.queuedFiles.length > 0 && _vm.editable
                              ? _c("q-btn", {
                                  staticClass: "excel-upload-btn",
                                  attrs: {
                                    icon: "delete",
                                    dense: "",
                                    outline: "",
                                    size: "sm",
                                    label: _vm.$language("파일 삭제"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFile(scope)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "list",
                    fn: function (scope) {
                      return [
                        scope.files && scope.files.length > 0
                          ? [
                              _c(
                                "q-list",
                                { attrs: { separator: "" } },
                                _vm._l(scope.files, function (file) {
                                  return _c(
                                    "q-item",
                                    { key: file.name },
                                    [
                                      _c(
                                        "q-item-section",
                                        [
                                          _c(
                                            "q-item-label",
                                            {
                                              staticClass:
                                                "full-width ellipsis",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(file.name) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                " Status: " +
                                                  _vm._s(file.__status) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "q-item-label",
                                            { attrs: { caption: "" } },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(file.__sizeLabel) +
                                                  " / " +
                                                  _vm._s(file.__progressLabel) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      file.__img
                                        ? _c(
                                            "q-item-section",
                                            {
                                              staticClass: "gt-xs",
                                              attrs: { thumbnail: "" },
                                            },
                                            [
                                              _c("img", {
                                                attrs: { src: file.__img.src },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ]
                          : [
                              _c(
                                "div",
                                { staticClass: "empty_dscription" },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.editable,
                                          expression: "editable",
                                        },
                                      ],
                                      staticStyle: {
                                        "text-align": "center",
                                        "padding-bottom": "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-chip",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            outline: "",
                                            square: "",
                                            icon: "info",
                                          },
                                        },
                                        [_vm._v(" Drag or Click ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "q-pl-md txtfileinfo",
                                      staticStyle: { "text-align": "left" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-positive" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$language(
                                                  "업로드 가능 확장자"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" : xlsx "),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "text-positive" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$language(
                                                  "업로드 가능 파일 수"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" : 1 "),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "text-positive" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$language("허용 파일 크기")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" : 100MB "),
                                    ]
                                  ),
                                  _c("q-uploader-add-trigger"),
                                ],
                                1
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 2,
                title: "양식 비교 및 업로드 준비",
                icon: "create_new_folder",
                done: _vm.step > 2,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  !_vm.fail.message1
                    ? _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-ball", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "regul-db-loading-style-text regul-db-animation",
                                  attrs: {
                                    "data-text": "양식비교중입니다....",
                                  },
                                },
                                [_vm._v(" 양식비교중입니다.... ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-comment", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                { staticClass: "regul-db-loading-style-text" },
                                [_vm._v(" " + _vm._s(_vm.fail.message1) + " ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 3,
                title: "업로드 결과 저장",
                icon: "add_comment",
                done: _vm.step > 3,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  !_vm.fail.message2
                    ? _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-ball", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "regul-db-loading-style-text regul-db-animation",
                                  attrs: {
                                    "data-text": "업로드 결과 저장 중....",
                                  },
                                },
                                [_vm._v(" 업로드 결과 저장 중.... ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "regul-db-loading-style-text" },
                                [
                                  _vm._v(
                                    " 총 건 수 : " +
                                      _vm._s(_vm.regulDbUpload.totalCnt) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-comment", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c("div", {
                                staticClass: "regul-db-loading-style-text",
                                domProps: {
                                  innerHTML: _vm._s(_vm.fail.message2),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 4,
                title: "내역 저장",
                icon: "add_comment",
                done: _vm.step > 4,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  !_vm.fail.message3
                    ? _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-ball", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "regul-db-loading-style-text regul-db-animation",
                                  attrs: {
                                    "data-text": "업로드 결과 저장 중....",
                                  },
                                },
                                [_vm._v(" 업로드 결과 저장 중.... ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "regul-db-loading-style-text" },
                                [
                                  _c("ICountUp", {
                                    attrs: {
                                      startVal: 0,
                                      endVal: _vm.regulDbUpload.ingCnt,
                                      decimals: 0,
                                      duration: 4,
                                      options: _vm.countOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-comment", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c("div", {
                                staticClass: "regul-db-loading-style-text",
                                domProps: {
                                  innerHTML: _vm._s(_vm.fail.message3),
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "regul-db-loading-style-text" },
                                [
                                  _c("ICountUp", {
                                    attrs: {
                                      startVal: 0,
                                      endVal: _vm.regulDbUpload.ingCnt,
                                      decimals: 0,
                                      duration: 4,
                                      options: _vm.countOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 5,
                title: "유효성 체크",
                icon: "create_new_folder",
                done: _vm.step > 5,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  !_vm.fail.message4
                    ? _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-ball", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "regul-db-loading-style-text regul-db-animation",
                                  attrs: { "data-text": "유효성 체크 중...." },
                                },
                                [_vm._v(" 유효성 체크 중.... ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm.fail.message4 === "VALID"
                    ? _c("c-table", {
                        ref: "table",
                        attrs: {
                          title: "유효성 실패 목록",
                          columns: _vm.validGrid.columns,
                          data: _vm.validGrid.data,
                          gridHeight: _vm.validGrid.height,
                          columnSetting: false,
                          isFullScreen: false,
                          isExcelDown: false,
                          filtering: false,
                        },
                      })
                    : _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-comment", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c("div", {
                                staticClass: "regul-db-loading-style-text",
                                domProps: {
                                  innerHTML: _vm._s(_vm.fail.message4),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 6,
                title: "동일 파일 업로드 체크",
                icon: "create_new_folder",
                done: _vm.step > 6,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  !_vm.fail.message5
                    ? _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-ball", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "regul-db-loading-style-text regul-db-animation",
                                  attrs: {
                                    "data-text":
                                      "최근 excel 업로드 건과 동일한 파일인지 검사 중....",
                                  },
                                },
                                [
                                  _vm._v(
                                    " 최근 excel 업로드 건과 동일한 파일인지 검사 중.... "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-comment", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c("div", {
                                staticClass: "regul-db-loading-style-text",
                                domProps: {
                                  innerHTML: _vm._s(_vm.fail.message5),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 7,
                title: "변경 내역 진행 중",
                icon: "create_new_folder",
                done: _vm.step > 7,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  !_vm.fail.message6
                    ? _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-ball", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "regul-db-loading-style-text regul-db-animation",
                                  attrs: {
                                    "data-text": "변경된 내역 작성 중....",
                                  },
                                },
                                [_vm._v(" 변경된 내역 작성 중.... ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _c(
                        "q-inner-loading",
                        { attrs: { showing: _vm.visible } },
                        [
                          _c(
                            "template",
                            { slot: "default" },
                            [
                              _c("q-spinner-comment", {
                                staticClass: "regul-db-spinner",
                                attrs: { size: "5.5em" },
                              }),
                              _c("div", {
                                staticClass: "regul-db-loading-style-text",
                                domProps: {
                                  innerHTML: _vm._s(_vm.fail.message6),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                ],
                1
              ),
            ]
          ),
          _c(
            "q-step",
            {
              attrs: {
                name: 8,
                title: "완료",
                icon: "create_new_folder",
                done: _vm.step > 8,
              },
            },
            [
              _c(
                "div",
                { staticClass: "regul-db-loading-style" },
                [
                  _c(
                    "q-inner-loading",
                    { attrs: { showing: _vm.visible } },
                    [
                      _c(
                        "template",
                        { slot: "default" },
                        [
                          _c("q-icon", {
                            staticClass: "regul-db-complete-icon text-green",
                            attrs: { name: "check" },
                          }),
                          _c(
                            "div",
                            { staticClass: "regul-db-loading-style-text" },
                            [_vm._v(" 완료 ")]
                          ),
                          _c("q-btn", {
                            staticClass: "regul-db-loading-style-text-sub",
                            attrs: { flat: "", label: "변경된 이력보기" },
                            on: { click: _vm.showHistory },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }